function handleValidateInfo(obj) {
    // configDeliveryCompany
    // configExpirationDate
    // configLocatorNumber
    // configNumberOfBoxesToBeStocked
    // configStorageWarehouse
    // configTrakingNumber
    let check = true;
    if (obj.delivery_company) {
        if (obj.delivery_company.length > 255) {
            obj.configDeliveryCompany.isMaxLength = true;
            obj.configDeliveryCompany.error = true;
            check = false;
        } else {
            obj.configDeliveryCompany.isMaxLength = false;
            obj.configDeliveryCompany.error = false;
        }
    }

    if (obj.expiration_date) {
        if (obj.expiration_date.length > 255) {
            obj.configExpirationDate.isMaxLength = true;
            obj.configExpirationDate.error = true;
            check = false;
        } else {
            obj.configExpirationDate.isMaxLength = false;
            obj.configExpirationDate.error = false;
        }
    }

    if (obj.tracking_number) {
        if (obj.tracking_number.length > 255) {
            obj.configTrakingNumber.isMaxLength = true;
            obj.configTrakingNumber.error = true;
            check = false;
        } else {
            obj.configTrakingNumber.isMaxLength = false;
            obj.configTrakingNumber.error = false;
        }
    }

    if (obj.locator_number) {
        if (obj.locator_number.length > 255) {
            obj.configLocatorNumber.isMaxLength = true;
            obj.configLocatorNumber.error = true;
            check = false;
        } else {
            obj.configLocatorNumber.isMaxLength = false;
            obj.configLocatorNumber.error = false;
        }
    }

    if (obj.number_of_boxes_to_be_stocked) {
        if (obj.number_of_boxes_to_be_stocked.length > 255) {
            obj.configNumberOfBoxesToBeStocked.isMaxLength = true;
            obj.configNumberOfBoxesToBeStocked.error = true;
            check = false;
        } else {
            obj.configNumberOfBoxesToBeStocked.isMaxLength = false;
            obj.configNumberOfBoxesToBeStocked.error = false;
        }
    }

    return check;
}

export { handleValidateInfo };
