<template>
    <div>
        <label
            :for="`upload-image-common-${id}`"
            :class="`btn ${$props.classLabel}`"
            :style="{ cursor: disabled ? 'pointer' : 'not-allowed' }"
            :disabled="disabled"
            ><span><i class="fa fa-upload" aria-hidden="true"></i></span> {{ $props.title }}</label
        >
        <input v-if="disabled" type="file" :id="`upload-image-common-${id}`" ref="imageInput" hidden @change="displayInfoImage" />
    </div>
</template>

<script>
import { uploadImageMethods } from '@/state/helpers';
import { showMessage } from '@/utils/messages';
import { convertKBtoMB } from '@/utils/format';

export default {
    name: 'UploadImages',
    props: {
        id: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: 'Upload a image'
        },
        classLabel: {
            type: String,
            default: 'btn-info'
        },
        disabled: {
            type: Boolean,
            default: true
        },
        processUpload: {
            type: Boolean,
            default: false
        },
        config: {
            type: Object,
            default: () => {
                {
                    return {
                        allowedSize: 1024,
                        allowedExtension: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/bmp']
                    };
                }
            }
        }
    },
    data() {
        return {
            fileInfo: {
                originName: '',
                extension: '',
                fileSize: 0
            },
            configUpload: this.config
        };
    },

    computed: {},

    methods: {
        ...uploadImageMethods,

        displayInfoImage() {
            const imageInput = this.$refs.imageInput;
            if (!imageInput.files.length) return;
            const file = imageInput.files[0];
            let { name, size, type } = file;
            this.fileInfo.originName = name;
            this.fileInfo.extension = name.split('.').pop(); // Lấy phần mở rộng của tệp
            this.fileInfo.type = type; // Lấy phần mở rộng của tệp
            this.fileInfo.fileSize = (size / 1024).toFixed(2); // Chuyển đổi size sang KB và làm tròn đến 2 chữ số thập phân
            this.$refs.imageInput.value = '';
            if (this.configUpload?.allowedExtension?.indexOf(this.fileInfo.type) <= -1) {
                showMessage(8080, this.$bvToast, this.$t('uploadFile.format'));
                return;
            }

            if (this.fileInfo.fileSize >= this.configUpload.allowedSize && !this.configUpload?.maxSize) {
                showMessage(8080, this.$bvToast, this.$t('uploadFile.maxSize1MB'));
                return;
            }

            if (this.fileInfo.fileSize >= this.configUpload?.maxSize) {
                showMessage(
                    8080,
                    this.$bvToast,
                    this.$t('uploadFile.maxSizeCustom', {
                        size: convertKBtoMB(this.configUpload?.maxSize)
                    })
                );
                return;
            }
            this.$emit('update:processUpload', true);
            const formData = new FormData();
            formData.append('image', file);
            this.acUploadImage(formData).then((data) => {
                this.$emit('dataFile', data.data);
                this.$emit('update:processUpload', false);
            });
            // Reset input bằng ref
        }
    },

    watch: {
        config: {
            handler: function (newConfig) {
                this.configUpload = newConfig;
            },
            immediate: true,
            deep: true
        }
    }
};
</script>

<style lang="scss" scoped>
label {
    display: inline-block;
    color: white;
    cursor: pointer;
}
</style>
