<template>
    <div>
        <ModalCommon :id="idInfo" :config="configModalCompatible" @save="handleSaveCompatible()" @close="handleCloseCompatible()">
            <form class="needs-validation" autocomplete="off">
                <div class="form-group row">
                    <label class="col-sm-3 mw--form" for="prizeLessStatus">{{ this.$t('pageCampaign.tabPrize.prizeLessStatus') }}</label>
                    <div class="col-sm-9">
                        <Multiselect
                            :id="`select-prize-less-status`"
                            :value.sync="formCompatible.prize_less_status"
                            :options="listPrizeLessStatus"
                            :config.sync="formCompatible.configReturnPrizeStatus"
                            @open="getListMasterPrizeLessStatus(formCompatible.configReturnPrizeStatus)"
                            @select="handleSelectPrizeLessStatus(formCompatible)"
                            @remove="handleRemovePrizeLessStatus(formCompatible)"
                        ></Multiselect>
                    </div>
                </div>

                <div v-if="hasPrizeLessStatus(formCompatible)" class="form-group row">
                    <label class="col-sm-3 mw--form" for="reconfirmationDate">{{ this.$t('pageCampaign.tabPrize.reconfirmationDate') }}</label>
                    <div class="col-sm-9">
                        <DateNormal
                            :year.sync="formCompatible.reconfirmation_date_y"
                            :month.sync="formCompatible.reconfirmation_date_m"
                            :day.sync="formCompatible.reconfirmation_date_d"
                            :full_date.sync="formCompatible.reconfirmation_date"
                            :config.sync="formCompatible.configReconfirmationDate"
                        >
                            <div v-if="formCompatible.configReconfirmationDate.error" class="invalid-feedback d-block">
                                <span>{{ formCompatible.configReconfirmationDate.errorText }}</span>
                            </div>
                        </DateNormal>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-3 mw--form" for="prizeResponseInstructions">{{
                        this.$t('pageCampaign.tabPrize.prizeResponseInstructions')
                    }}</label>
                    <div class="col-sm-9">
                        <InputText
                            :id="`prizeResponseInstructions`"
                            :model.sync="formCompatible.prize_refund_instructions"
                            :config.sync="formCompatible.configPrizeRefundInstructions"
                        />
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-3 mw--form" for="storageFee">{{ this.$t('pageCampaign.tabPrize.storageFee') }}</label>
                    <div class="col-sm-9">
                        <InputText :id="`storageFee`" :model.sync="formCompatible.preserve_fee" :config.sync="formCompatible.configPreserveFee" />
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-3 mw--form" for="storageFeeOccurrenceDate">{{
                        this.$t('pageCampaign.tabPrize.storageFeeOccurrenceDate')
                    }}</label>
                    <div class="col-sm-9">
                        <DateNormal
                            :year.sync="formCompatible.date_of_preserve_y"
                            :month.sync="formCompatible.date_of_preserve_m"
                            :day.sync="formCompatible.date_of_preserve_d"
                            :full_date.sync="formCompatible.date_of_preserve"
                            :config.sync="formCompatible.configDateOfPreserve"
                        >
                            <div v-if="formCompatible.configDateOfPreserve.error" class="invalid-feedback d-block">
                                <span>{{ formCompatible.configDateOfPreserve.errorText }}</span>
                            </div>
                        </DateNormal>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-3 mw--form" for="remarks">{{ this.$t('pageCampaign.tabPrize.remarks') }}</label>
                    <div class="col-sm-9">
                        <InputText :id="`remarks`" :model.sync="formCompatible.reference" :config.sync="formCompatible.configReference" />
                    </div>
                </div>
            </form>
        </ModalCommon>
    </div>
</template>

<script>
import _ from 'lodash';
import { InputText } from '@/components/Input';
import { CONFIG_MODALCOMPATIBLE } from '../../constans';
import { handleValidateCompatible } from './validate';
import { keyMaster, masterMethods } from '@/state/helpers';
import ModalCommon from '@/components/Modal/main.vue';
import Multiselect from '@/components/Multiselect/main.vue';
import DateNormal from '@/components/DateCustoms/DateNormal/main.vue';
export default {
    name: 'PopUpCompatible',
    components: {
        ModalCommon,
        InputText,
        Multiselect,
        DateNormal
    },
    props: {
        id: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        isShow: {
            type: Boolean,
            default: false
        },
        dataCompatible: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    data() {
        return {
            idInfo: this.id,
            formCompatible: this.dataCompatible,
            formCompatibleCopy: _.cloneDeep(this.dataCompatible),
            configModalCompatible: CONFIG_MODALCOMPATIBLE,
            listPrizeLessStatus: []
        };
    },
    methods: {
        ...masterMethods,

        handleSaveCompatible() {
            let checkValidate = handleValidateCompatible(this.formCompatible);
            if (!checkValidate) {
                this.configModalCompatible.model = true;
                return;
            }
            this.formCompatibleCopy = _.cloneDeep(this.formCompatible);
            this.$emit('update:dataCompatible', this.formCompatible);
            this.$emit('update:isShow', false);
        },
        handleCloseCompatible() {
            let checkValidate = handleValidateCompatible(this.formCompatible);
            if (!checkValidate) {
                this.configModalCompatible.model = true;
                return;
            }
            this.formCompatible = this.formCompatibleCopy;
            this.$emit('update:dataCompatible', this.formCompatible);
            this.$emit('update:isShow', false);
        },

        async getListMasterPrizeLessStatus(configReturnPrizeStatus) {
            if (configReturnPrizeStatus.loading) return;
            configReturnPrizeStatus.loading = true;
            this.listPrizeLessStatus = [];
            try {
                let data = await this.listMaster(keyMaster['prize.prize_less_status']);
                this.listPrizeLessStatus = data;
            } catch (error) {
                console.log(error);
            } finally {
                configReturnPrizeStatus.loading = false;
            }
        },

        handleSelectPrizeLessStatus(param) {
            if (param.prize_less_status?.value !== '確認中' && param.prize_less_status?.value !== '保管') {
                param.reconfirmation_date = null;
            }
        },
        handleRemovePrizeLessStatus(param) {
            param.reconfirmation_date = null;
        },
        hasPrizeLessStatus(param) {
            return param.prize_less_status?.value === '確認中' || param.prize_less_status?.value === '保管';
        }
    },
    watch: {
        id: {
            handler: function (valId) {
                this.idInfo = valId;
            },
            immediate: true
        },
        title: {
            handler: function (valTitle) {
                this.configModalCompatible.title = valTitle;
            },
            immediate: true
        },
        isShow: {
            handler: function (valShow) {
                this.configModalCompatible.model = valShow;
            },
            immediate: true
        }
    }
};
</script>

<style lang="scss" scoped></style>
