<template>
    <div>
        <div v-if="!isLoading">
            <div v-if="isBackgroundImageEditor" class="text-right mb-3">
                <UploadImagesCommon
                    :classLabel="`btn-secondary`"
                    @dataFile="handleFileBackgroundEditor"
                    :title="`${$t('btn.addbackgroundEditor')}`"
                    :id="`bgrEditorImg`"
                    :config="configUpImage"
                />
            </div>
            <div class="box-editor" :class="`${configError?.error ? 'is-invalid-editor' : 'editor__content'}`">
                <Editor
                    :id="id"
                    :ref="refEditor"
                    :SetContent="SetContent"
                    :model-events="modelEvents"
                    v-model="dataModel"
                    :api-key="apiKey ?? keyEditor"
                    @init="onEditorInit"
                    :init="configEditor"
                />
            </div>

            <div v-if="configError?.error" class="invalid-feedback d-block">
                <span v-if="configError.isRequired">{{
                    $t('validateField.required', {
                        field: configError.errorField
                    })
                }}</span>
            </div>
        </div>
        <div v-else class="d-flex justify-content-center">
            <LoadingIcon />
        </div>
    </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
import LoadingIcon from '@/components/Loading/main.vue';
import UploadImagesCommon from '@/components/Uploads/Image/main.vue';

import { editorMethods } from '@/state/helpers';

export default {
    name: 'EditorCommon',
    components: {
        Editor,
        LoadingIcon,
        UploadImagesCommon
    },
    props: {
        id: {
            type: [String, Number, null],
            default: 'editor-id'
        },
        model: {
            type: String,
            required: true,
            default: ''
        },
        refEditor: {
            type: [String, Number, null],
            default: 'editor'
        },
        SetContent: {
            type: String,
            default: 'SetContent'
        },
        modelEvents: {
            type: String,
            default: 'change keydown blur focus paste'
        },
        apiKey: {
            type: [String, Number, null],
            default: null
        },
        isLoading: {
            type: Boolean,
            default: () => false
        },
        isBackgroundImageEditor: {
            type: Boolean,
            default: () => false
        },
        configInit: {
            type: Object,
            default: () => {}
        },
        configError: {
            type: Object,
            default: () => {
                return {
                    error: false,
                    valueSync: null,
                    isRequired: false,
                    errorField: '',
                    typesValidate: []
                };
            }
        }
    },
    data() {
        return {
            keyEditor: JSON.parse(localStorage.getItem('user'))?.key_editor, //process.env.VUE_APP_KEY_EDITOR
            dataModel: '',
            configEditor: {
                height: 500,
                content_style: 'p { margin: 0 },.tox .tox-statusbar{display: none}',
                menubar: true,
                automatic_uploads: true,
                forced_root_block: '', // Tắt chế độ tự sinh thẻ <p>
                plugins: [
                    'hr advlist autolink lists link image charmap',
                    'searchreplace visualblocks code fullscreen',
                    'print preview anchor insertdatetime media template',
                    'paste code help wordcount table code lists imagetools code'
                ],
                toolbar:
                    'myCustomToolbarButton hr undo redo | forecolor backcolor | formatselect | bold italic | \
                                          alignleft aligncenter alignright | \
                                          link image media | \
                                          bullist numlist outdent indent | help code\
                                          table tableinsertdialog tablecellprops tableprops | fullscreen | code',
                table_toolbar:
                    'tableprops tabledelete tablemergecells tablesplitcells | tableinsertrowbefore tableinsertrowafter tablerowprops tabledeleterow | tableinsertcolbefore tableinsertcolafter tablecellprops tabledeletecol',
                images_upload_url: '', // Your upload URL
                images_upload_handler: this.handleUploadImages,
                setup: this.setupEditor
            },
            configUpImage: {
                maxSize: 5120
            },
            editorInstance: null,
            backgroundEditor: null
        };
    },
    computed: {
        getStateEditor() {
            return this.$store.state.editor.configBgImgDefault;
        }
    },
    methods: {
        ...editorMethods,

        setupEditor(editor) {
            editor.on('init', () => {
                editor.execCommand('mceToggleEditor');
                this.onEditorInit(editor);
            });
            editor.on('change', () => {
                this.$emit('change');
            });
            editor.on('blur', () => {
                this.$emit('blur');
            });
            editor.on('OpenWindow', function () {
                const tabs = document.querySelectorAll('.tox-dialog__body .tox-dialog__body-nav .tox-dialog__body-nav-item.tox-tab');
                tabs.forEach((tab) => {
                    if (tab?.textContent === 'General') {
                        tab.style.display = 'none';
                    }
                    if (tab.textContent === 'Upload') {
                        tab.click();
                    }
                });
            });
            this.editorInstance = editor;
        },

        onEditorInit() {
            this.$emit('init', this.editorInstance);
        },

        handleUploadImages(blobInfo, success, failure) {
            const formData = new FormData();
            formData.append('image', blobInfo.blob());
            fetch(`${process.env.VUE_APP_APIURL}/upload/image`, {
                headers: {
                    Authorization: 'Bearer ' + window.$cookies.get('token')
                },
                method: 'POST',
                body: formData
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data?.code === 200) {
                        success(data?.data?.path ?? '');
                    } else {
                        failure(this.$t('uploadFile.maxSize5MB'));
                    }
                })
                .catch((error) => {
                    console.log('error', error);
                    failure(error?.message ?? 'Lỗi upload ảnh');
                });
        },

        onHandleBackgroundImage() {
            const { backgroundImage, backgroundSize, backgroundPosition, backgroundRepeat } = this.getStateEditor;
            if (!this.editorInstance || !backgroundImage) return;
            const selectedContent = this.editorInstance.getContent();
            const backgroundDiv = `<div style="background-image: url(${backgroundImage});background-size: ${backgroundSize};background-position: ${backgroundPosition};background-repeat: ${backgroundRepeat};min-height: 300px;max-height: 500px;overflow: hidden;">${selectedContent}</div>`;
            this.editorInstance.setContent(backgroundDiv);
            this.$emit('update:model', backgroundDiv);
        },

        handleFileBackgroundEditor(file) {
            this.backgroundEditor = file;
            this.registerDataEditor({ backgroundImage: file.path });
            this.onHandleBackgroundImage();
        }
    },
    watch: {
        model: {
            handler: function (newModel) {
                this.dataModel = newModel;
            },
            deep: true,
            immediate: true
        },

        dataModel: {
            handler: function (newModel) {
                let configErr = this.configError;
                configErr.error = false;
                configErr.isRequired = false;
                configErr.valueSync = newModel;

                this.$emit('update:configError', configErr);
                this.$emit('update:model', newModel);
            },
            immediate: true
        },

        configInit: {
            handler: function (newConfig) {
                this.configEditor = {
                    ...this.configEditor,
                    ...newConfig
                };
            },
            immediate: true
        }
    }
};
</script>

<style lang="scss">
.box-editor {
    border-radius: 5px;
    .tox.tox-tinymce {
        border: none;
    }
    .tox-tinymce {
        border: 1px solid #ced4da;
        border-radius: 5px;
    }
    &.editor__content {
        border: 1px solid #ced4da;
    }
    &.is-invalid-editor {
        border: 1px solid #ff3d60;
    }
}
</style>
