function handleValidateCompatible(obj) {
    // configDateOfPreserve
    // configPreserveFee
    // configPrizeLessStatus
    // configPrizeRefundInstructions
    // configReconfirmationDate
    // configReference
    // configReturnPrizeStatus

    let check = true;
    if (obj.prize_refund_instructions) {
        if (obj.prize_refund_instructions.length > 255) {
            obj.configPrizeRefundInstructions.isMaxLength = true;
            obj.configPrizeRefundInstructions.error = true;
            check = false;
        } else {
            obj.configPrizeRefundInstructions.isMaxLength = false;
            obj.configPrizeRefundInstructions.error = false;
        }
    }

    if (obj.reference) {
        if (obj.reference.length > 255) {
            obj.configReference.isMaxLength = true;
            obj.configReference.error = true;
            check = false;
        } else {
            obj.configReference.isMaxLength = false;
            obj.configReference.error = false;
        }
    }

    if (obj.preserve_fee) {
        if (obj.preserve_fee.length > 255) {
            obj.configPreserveFee.isMaxLength = true;
            obj.configPreserveFee.error = true;
            check = false;
        } else {
            obj.configPreserveFee.isMaxLength = false;
            obj.configPreserveFee.error = false;
        }
    }

    return check;
}

export { handleValidateCompatible };
