<template>
    <div :id="id" :class="`box_tags--common ${configDefault.error ? 'is-invalid' : ''}`">
        <b-form-tags
            style="min-width: 150px"
            :id="id"
            v-model="modelTag"
            separator="#"
            :tag-class="`custom__tags ${checkPre} `"
            input-id="tags-validation"
            :input-attrs="{ 'aria-describedby': 'tags-validation-help' }"
            :tag-validator="tagValidator"
            :tag-variant="configDefault.tagVariant"
            :addButtonText="$t('btn.addTag')"
            :size="configDefault.size"
            :placeholder="configDefault.placeholder ? configDefault.placeholder : ''"
            invalid-tag-text=""
            duplicate-tag-text=""
            @blur="handleBlur"
        ></b-form-tags>
        <div class="d-block invalid-feedback">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'KeywordTagComponent',
    props: {
        id: {
            type: String,
            default: 'KeywordsTag'
        },
        model: {
            type: Array,
            required: true,
            default: () => []
        },
        checkPre: {
            type: String,
            default() {
                return '';
            }
        },
        config: {
            type: Object,
            default() {
                return {
                    tagVariant: 'primary',
                    size: 'md',
                    placeholder: '',
                    valueSync: [],
                    maxlength: 255,
                    error: false
                };
            }
        }
    },
    data() {
        return {
            modelTag: this.model,
            configDefault: {
                tagVariant: 'primary',
                size: 'md',
                placeholder: '',
                valueSync: [],
                maxlength: 255,
                error: false
            }
        };
    },
    computed: {
        displayedTags() {
            return this.modelTag.map((tag) => '#' + tag);
        }
    },
    watch: {
        model: {
            handler: function (newModel) {
                this.modelTag = newModel;
            },
            immediate: true
        },
        config: {
            handler: function (newConfig) {
                this.configDefault = {
                    ...this.configDefault,
                    ...newConfig
                };
            },
            immediate: true,
            deep: true
        },

        modelTag: {
            handler: function () {
                this.configDefault.error = false;
                this.configDefault.valueSync = this.modelTag;

                this.$emit('update:config', this.configDefault);
                this.$emit('update:model', this.modelTag);
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        tagValidator(tag) {
            return tag.length < this.configDefault.maxlength;
        },

        handleBlur() {
            this.$emit('blur');
        }
    }
};
</script>

<style lang="scss" scoped>
.box_tags--common {
    &.is-invalid {
        .b-form-tags {
            border: 1px solid #ff3d60 !important;
        }
    }
    .custom__tags {
        font-size: 14.4px;
    }
}
:deep(button.btn.b-form-tags-button.py-0.btn-outline-secondary) {
    width: 65px;
}
</style>
